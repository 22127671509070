import React from "react";
import { TogglerProps } from "../../types/types";

export const LabelTogglerComponent = (props: TogglerProps) => {
  return (
    <label>
      Show Labels
      <input
        type="checkbox"
        checked={props.checked}
        onChange={(e) => props.onToggle(e.target.checked)}
      />
    </label>
  );
};
