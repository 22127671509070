import React from "react";
import Popup from "reactjs-popup";
import { circularStyledButton } from "../fancyComponents/buttonStyle";
import {
  LevelSliderProps,
  LevelSlider,
} from "../UIComponents/VisualizationSubComponents/LevelSlider";
import {
  CyclingComponent
} from "../UIComponents/VisualizationSubComponents/CyclingComponent";
import { LabelTogglerComponent } from "../UIComponents/VisualizationSubComponents/LabelTogglerComponent";
import { TogglerProps } from "../types/types";
import { TimelapseController, AutoadvancingControllerProps } from "../UIComponents/VisualizationSubComponents/TimelapseController";

export interface VisualizationProps {
  levelInformation: LevelSliderProps;
  labelTogglerOptions: TogglerProps;
  cyclingOptions: AutoadvancingControllerProps;
  timelapseOptions: AutoadvancingControllerProps;
  autoAdvanceMode: "timelapse" | "cycling"
}

export const VisualizationPopup = (props: VisualizationProps) => {
  const autoAdvancingToggler = () => {
    return props.autoAdvanceMode==="timelapse" ? 
    <TimelapseController {...props.timelapseOptions} /> :
    <CyclingComponent {...props.cyclingOptions} /> 
  }

  const displayContent = () => {
    return (
      <div>
        {autoAdvancingToggler()}
        <hr />
        <LabelTogglerComponent
          checked={props.labelTogglerOptions.checked}
          onToggle={props.labelTogglerOptions.onToggle}
        />
        <hr />
        <LevelSlider
          currentLevel = {props.levelInformation.currentLevel}
          maxLevel = {props.levelInformation.maxLevel}
          setLevel={props.levelInformation.setLevel}
        />
      </div>
    );
  };

  return (
    <Popup
      trigger={
        <button style={circularStyledButton} title="Visualization Options">
          V
        </button>
      }
    >
      {displayContent()}
    </Popup>
  );
};
