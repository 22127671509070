import React, { useState } from 'react';
import { Color, Shape, Vector2 } from 'three';

import { IMetadata, INodeData, IVoronoiCell } from '../types/types';
import { InformativePopup } from './InformativePopup';
import { LabelComponent } from './LabelComponent';
import { PerimeterComponent } from './PerimeterComponent';

export interface IVoronoiPolygonProps {
  node: INodeData;
  selected: boolean;
  metadata: IMetadata;
  showLabel: boolean;
  fill: boolean;
  deltaMode: boolean;
  popUpGettingData: nodeInfoGetter;
  setSelection: (selection: string) => void;
  setTextSelection: (selection?: string) => void;
  onClick: (name: string | undefined, delta: -1 | 1) => void;
}

export type nodeInfoGetter = (name: string) => nodeHierarchyInfo;
export type nodeHierarchyInfo = { name: string; basePercentage: number }[];

const HISTORICAL_MAX_NUMBER = 4;
const CHANGED_COLOR = new Color(200 / 255, 200 / 255, 200 / 255);
const OLD_COLOR = new Color(255 / 255, 255 / 255, 255 / 255);
const ADDED_COLOR = new Color(58 / 255, 224 / 255, 141 / 255);
const DELETED_COLOR = new Color(245 / 255, 39 / 255, 39 / 255);

export const VoronoiCell = (props: IVoronoiPolygonProps) => {
  const [delayIntervalId, setDelay] = useState<number | undefined>();
  const [showPopup, setShownPopup] = useState(false);
  const [selected, setSelected] = useState(false);
  const { node } = props;

  const changeZoom = (node: INodeData, button: number) => {
    console.log(
      `Click detected on ${node.name} - ${button === 2 ? "right" : "left"} click`
    );
    props.onClick(node.name, button === 2 ? -1 : 1);
  };

  const buildLink = () => {
    return props.metadata.repository.replace(
      ".git",
      `/blob/${props.metadata.commitHash}/${node.name}`
    );
  };

  const sendToRelevantPage = () => {
    window.open(buildLink(), "_blank");
  };

  const getOutOfArea = () => {
    setSelected(false);
    clearTimeout(delayIntervalId);
    setShownPopup(false);
  };

  const getInsideArea = () => {
    setSelected(true);
    const timeFunction = window.setTimeout(() => setShownPopup(true), 250);
    setDelay(timeFunction);
  };

  const isOverallSelected = () => props.selected || selected;

  const getHoverText = (textHovered?: string) =>
    props.setTextSelection(textHovered);

  const computeColor = () => {
    return props.deltaMode ? deltaModeColor() : normalizeColor(node);
  };

  const normalizeColor = (node: INodeData): Color => {
    return new Color(
      node.color.red / 255,
      node.color.green / 255,
      node.color.blue / 255
    );
  };

  const deltaModeColor = () => {
    const deltaInfo = props.node.deltaInfo!;
    if (deltaInfo.epochsSinceModification >= HISTORICAL_MAX_NUMBER) {
      return OLD_COLOR;
    }

    switch (deltaInfo.lastModificationType) {
      case "change":
        return CHANGED_COLOR;
      case "increase":
        return ADDED_COLOR;
      case "decrease":
        return DELETED_COLOR;
      default:
        return OLD_COLOR;
    }
  };

  const computeDeltaModeOpacity = () => {
    const numberOfSteps = HISTORICAL_MAX_NUMBER;
    if (props.node.deltaInfo!.epochsSinceModification < HISTORICAL_MAX_NUMBER) {
      return 1 - props.node.deltaInfo!.epochsSinceModification / numberOfSteps;
    }
    return 0;
  };

  const filledMesh = () => {
    const cell = node.voronoiObject;
    const shape = new Shape(
      cell.polygon.points.map(
        (p) => new Vector2(p.x - cell.site.x, p.y - cell.site.y)
      )
    );
    const color = computeColor();
    return (
      <mesh
        onPointerDown={(e) =>
          e.ctrlKey && e.button === 0
            ? sendToRelevantPage()
            : changeZoom(node, e.button)
        }
        onPointerOver={() => getInsideArea()}
        onPointerOut={() => getOutOfArea()}
      >
        <shapeGeometry attach="geometry" args={[shape]} />
        <meshBasicMaterial
          attach="material"
          color={color}
          transparent={props.deltaMode}
          opacity={props.deltaMode ? computeDeltaModeOpacity() : 1}
        />
      </mesh>
    );
  };

  const buildLabel = (showLabel: boolean) => {
    return showLabel ? (
      <LabelComponent
        name={node.name}
        level={node.branchIndex!}
        maxLevel={props.metadata.maxLevel}
        hoveringFunction={getHoverText}
        transparency={props.deltaMode ? computeDeltaModeOpacity() : 1}
      />
    ) : null;
  };

  const fillMeshIfNeeded = () => {
    return node.leaf || props.fill ? filledMesh() : "";
  };

  const drawPerimeter = (cell: IVoronoiCell) => {
    const siteCenteredPolygon = {
      points: cell.polygon.points.map((p) => {
        return { x: p.x - cell.site.x, y: p.y - cell.site.y };
      }),
    };
    return (
      <PerimeterComponent
        polygon={siteCenteredPolygon}
        level={Math.min(node.branchIndex!, 7)}
        selected={isOverallSelected()}
      />
    );
  };

  const showPopupInfo = () => {
    return (
      <InformativePopup
        getter={props.popUpGettingData}
        name={node.name}
        weight={node.weight}
        delta={node.deltaInfo!.deltaWeight!}
      />
    );
  };

  const showPopupIfNeeded = () =>
    isOverallSelected() && showPopup ? showPopupInfo() : "";

  const draw = () => {
    const cell = node.voronoiObject;
    return cell ? (
      <group position={[cell.site.x, cell.site.y, 0]}>
        {fillMeshIfNeeded()}
        {drawPerimeter(cell)}
        {buildLabel(props.showLabel)}
        {showPopupIfNeeded()}
      </group>
    ) : null;
  };

  return draw();
};

export default VoronoiCell;
