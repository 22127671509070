import { AutoadvancingControllerProps } from "./TimelapseController";

export const CyclingComponent = (props: AutoadvancingControllerProps) => {
  return (
    <label>
      Toggle automatic advancing
      <input
        type="checkbox"
        checked={props.checked}
        onChange={(e) => props.onToggle(e.target.checked)}
      />
      every
      <input
        type="text"
        value={props.intervalDelay}
        onChange={(e) => props.setIntervalDelay(parseInt(e.target.value))}
      />{" "}
      ms
    </label>
  );
};
