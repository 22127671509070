import "reactjs-popup/dist/index.css";

import React, { CSSProperties, useState } from "react";
import ReactModal from "react-modal";

import setupIcon from "../../assets/setup.png";
import { beautifyGitRepoName, distinct } from "../../utils/utils";
import { BoundingBox } from "../types/types";
import { NameFiltering } from "./nameFilteringDefiner/NameFiltering";
import { NameFilteringDefiner } from "./nameFilteringDefiner/NameFilteringDefiner";
import { RepositorySelector } from "./repositorySelector/RepositorySelector";
import { BoundingBoxSelectorComponent } from "./runningOptions/BoundingBoxSelectorComponent";
import { MetricSelector } from "./runningOptions/MetricSelector";
import { ShrinkingModeComponent } from "./runningOptions/ShrinkingModeComponent";
import { LastElementsChooserProps } from "./seriesFiltering/LastElementsChooser";
import { SamplingProps } from "./seriesFiltering/SamplingComponent";
import { SeriesFilteringOptions } from "./seriesFiltering/SeriesFilteringOptions";
import {
  SeriesFilteringPopup,
  SeriesFilteringProps,
} from "./seriesFiltering/SeriesFilteringPopup";
import { SettingsOptions } from "./SettingsOptions";
import { StartProcessingButton } from "./StartProcessingButton";
import { TimeSamplingOptions } from "../../BackendMessage";
import { ByTimeProps } from "./seriesFiltering/ByTimeElementsChooser";

export interface SettingsProps {
  statusDisabled: boolean;
  options: SettingsOptions;
  setOptions: (options: SettingsOptions) => void;
  onClickedProcessingButton: () => void;
}

ReactModal.setAppElement("body");

export const SettingsModal = (props: SettingsProps) => {
  const { options } = props;

  const addNewRepo = (repoUrl: string) => {
    const newRepository = {name: beautifyGitRepoName(repoUrl), remoteRepo: repoUrl };
    const multipleRepositories = [...options.repositories, newRepository];
    const newRepositories = distinct(multipleRepositories);
    props.setOptions({
      ...options,
      repositories: newRepositories,
      repository: repoUrl,
    });
  };

  const chooseRepository = (repositoryName: string) => {
    props.setOptions({ ...options, repository: repositoryName });
  };

  const onBoundingBoxChange = (boundingBox: BoundingBox): void => {
    props.setOptions({ ...options, boundingBox });
  };

  const onSamplingTickChange = (enabled: boolean): void => {
    const { filteringOptions } = options;
    const newFilteringOptions = {
      ...filteringOptions,
      samplingOptions: {
        enabled,
        frequency: filteringOptions.samplingOptions.frequency,
      },
    };
    props.setOptions({ ...options, filteringOptions: newFilteringOptions });
  };

  const onSamplingFrequencyChange = (frequency: number | undefined): void => {
    const { filteringOptions } = options;
    const newFilteringOptions = {
      ...filteringOptions,
      samplingOptions: {
        enabled: filteringOptions.samplingOptions.enabled,
        frequency,
      },
    };
    props.setOptions({ ...options, filteringOptions: newFilteringOptions });
  };

  const onLastElementTickChange = (enabled: boolean): void => {
    const { filteringOptions } = options;
    const newFilteringOptions = {
      ...filteringOptions,
      lastElementOptions: {
        enabled,
        frequency: filteringOptions.samplingOptions.frequency,
      },
    };
    props.setOptions({ ...options, filteringOptions: newFilteringOptions });
  };

  const onLastElementNumberChange = (amount: number | undefined): void => {
    const { filteringOptions } = options;
    const newFilteringOptions = {
      ...filteringOptions,
      lastElementOptions: {
        enabled: filteringOptions.samplingOptions.enabled,
        amount,
      },
    };
    props.setOptions({ ...options, filteringOptions: newFilteringOptions });
  };

  const onDateSelectChange = (byDateGrouping: TimeSamplingOptions): void => {
    const { filteringOptions } = options;
    const newFilteringOptions = { ...filteringOptions, byDateGrouping };
    props.setOptions({ ...options, filteringOptions: newFilteringOptions });
  };

  const onMetricChange = (metric: string): void => {
    props.setOptions({ ...options, metric });
  };

  const onNameFilteringChange = (nameFiltering: NameFiltering): void => {
    props.setOptions({ ...options, nameFiltering });
  };

  const buildSamplingRangeOptions = (
    options: SeriesFilteringOptions
  ): SeriesFilteringProps => {
    return {
      samplingOptions: setSamplingOptions(options),
      lastElementsOptions: setLastElementOptions(options),
      byTimeOptions: setByTimeOptions(options),
    };
  };

  const setSamplingOptions = (
    options: SeriesFilteringOptions
  ): SamplingProps => {
    const samplingOptions = options.samplingOptions;
    const setSampling = onSamplingTickChange;
    const setFrequency = onSamplingFrequencyChange;
    return { samplingOptions, setSampling, setFrequency };
  };

  const setLastElementOptions = (
    options: SeriesFilteringOptions
  ): LastElementsChooserProps => {
    const chooserOptions = options.lastElementOptions;
    const setEnabled = onLastElementTickChange;
    const setAmount = onLastElementNumberChange;
    return { chooserOptions, setEnabled, setAmount };
  };

  const setByTimeOptions = (options: SeriesFilteringOptions): ByTimeProps => {
    const currentValue = options.byDateGrouping;
    const setValue = onDateSelectChange;
    return { currentValue, setValue };
  };

  const displayContent = () => {
    return (
      <div style={{ textAlign: "left", lineHeight: 1.6, zIndex: 500 }}>
        <RepositorySelector
          repositories={options.repositories}
          onSelectChange={chooseRepository}
          onInputChange={addNewRepo}
        />
        <hr />
        <div style={HeaderStyle}>Simulation Options</div>
        <BoundingBoxSelectorComponent
          boundingBox={options.boundingBox}
          onChangeBoundingBox={onBoundingBoxChange}
        />
        <br />
        <MetricSelector
          metrics={options.metrics}
          onMetricChange={onMetricChange}
        />
        <br />
        <ShrinkingModeComponent
          checked={options.shrinking}
          onToggle={(newValue) =>
            props.setOptions({ ...options, shrinking: newValue })
          }
        />
        <br />
        <hr />
        <NameFilteringDefiner
          nameFiltering={options.nameFiltering}
          onNameFilteringChange={onNameFilteringChange}
        />
        <hr />
        <br />
        <SeriesFilteringPopup
          {...buildSamplingRangeOptions(options.filteringOptions)}
        ></SeriesFilteringPopup>
        <br />
        <StartProcessingButton
          disabled={props.statusDisabled}
          onClickedProcessingButton={doClick}
        />
        <br />
      </div>
    );
  };

  const doClick = (): void => {
    if (options.repository!=="NOTHING" ) {
    handleCloseModal();
    props.onClickedProcessingButton();
    }
  };

  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const style: CSSProperties = { position: "absolute", zIndex: 500 };

  return (
    <div style={style}>
      <img
        src={setupIcon}
        onClick={handleOpenModal}
        style={{ width: "50px", height: "50px" }}
      />
      <ReactModal
        isOpen={showModal}
        contentLabel="onRequestClose Example"
        onRequestClose={handleCloseModal}
        shouldCloseOnOverlayClick={true}
      >
        {displayContent()}
      </ReactModal>
    </div>
  );
};

export const HeaderStyle: React.CSSProperties = {
  fontSize: "130%",
  fontWeight: "bold",
};
