import React from "react";
import Popup from "reactjs-popup";

import { IMetadata } from "../types/types";
import { MetadataInformationComponent } from "../TitleComponent";
import infoButton from "../../assets/info.png";

export interface MetadataPopupProps {
  metadata: IMetadata;
  rootWeight: number;
  deltaRootWeight: number;
}

export const MetadataPopup = (props: MetadataPopupProps) => {
  return (
    <Popup
      keepTooltipInside=".mainCanvas"
      position="right bottom"
      contentStyle={{ width: "auto" }}
      trigger={
        <img
          src={infoButton}
          style={{ height: "30px", width: "30px" }}
          alt="Info"
        />
      }
      on="hover"
    >
      <MetadataInformationComponent
        metadata={props.metadata}
        rootWeight={props.rootWeight}
        deltaWeight={props.deltaRootWeight}
      />
    </Popup>
  );
};
