import React, { useState } from 'react';

import { clamp, parseNodeName } from '../utils/utils';
import { IIndexedNodeData, IMetadata, INodeData } from './types/types';
import VoronoiCell, { nodeHierarchyInfo } from './VoronoiComponents/VoronoiCell';

export interface ITreemapProps {
  data: IIndexedNodeData;
  metadata: IMetadata;
  deltaMode: boolean;
  showLabels: boolean;
  currentlyShownLevels: number;
  selectedPolygon?: string;
  selectedPattern?: string;
  zoomOn: (nodeName: string, zoomIn: boolean) => void;
}

export const TreemapContainer = (props: ITreemapProps) => {
  const buildMeshes = (data: IIndexedNodeData) => {
    return Object.values(data).map((node) => {
      return node.level <= props.currentlyShownLevels ? 
        buildMesh(node) :
        null;
    });
  };

  const buildNodePercentageInformation = (
    name: string,
    percentage = 1.0
  ): nodeHierarchyInfo => {
    if (!props.data[name]) {
      return [];
    }
    const theData = props.data[name];
    return [{ name: name, basePercentage: percentage }].concat(
      buildNodePercentageInformation(
        theData.parent || "",
        theData.percentage * percentage
      )
    );
  };

  const isMaxVisibleLevel = (n: number): boolean => {
    return n === props.currentlyShownLevels
  };

  const buildMesh = (entity: INodeData) => {
    return (
      <VoronoiCell
        node={entity}
        key={entity.name}
        metadata={props.metadata}
        selected={toBeSelected(entity.name)}
        setSelection={setSelectedPolygonName}
        setTextSelection={setTextSelectedPolygonName}
        popUpGettingData={buildNodePercentageInformation}
        onClick={changeLevelByName}
        fill={isMaxVisibleLevel(entity.level)}
        showLabel={props.showLabels}
        deltaMode={props.deltaMode}
      />
    );
  };

  const nameMatches = (name: string): boolean => {
    return parseNodeName(name)
      .toUpperCase()
      .includes(props.selectedPattern!.toUpperCase());
  };

  const toBeSelected = (name: string): boolean => {
    if (props.selectedPolygon) {
      return props.selectedPolygon === name;
    }
    if (textSelectedName) {
      return textSelectedName === name;
    }
    if (selectedName) {
      return selectedName === name;
    }
    return props.selectedPattern ? nameMatches(name) : false;
  };

  const [selectedName, setSelectedPolygonName] = useState<string | undefined>();
  const [textSelectedName, setTextSelectedPolygonName] = useState<
    string | undefined
  >();

  const [currentLevel, setCurrentLevel] = useState(0);

  const changeLevelByName = (newName: string | undefined, delta: 1 | -1) => {
    const newLevel = clamp(currentLevel + delta, 0, props.metadata.maxLevel);
    props.zoomOn(newName || "$root", delta===1);
  };

  return <group>{buildMeshes(props.data)}</group>;
};

export default TreemapContainer;
