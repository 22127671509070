import React from "react";
import { clamp } from "../../utils/utils";

export interface IIndexChooserProps {
  showDate: boolean;
  currentIndex?: number;
  currentDate?: Date;
  counter: number;
  setNewIndex: (newIndex: number) => void;
}

export const IndexChooser = (props: IIndexChooserProps) => {
  const reset = () => props.setNewIndex(0);
  const toEnd = () => props.setNewIndex(props.counter - 1);
  const increase = () => props.setNewIndex(clampValue(props.currentIndex! + 1));
  const decrease = () => props.setNewIndex(clampValue(props.currentIndex! - 1));
  const clampValue = (x: number) => clamp(x, 0, props.counter - 1);

  const setNewIndexWithSensibleValues = (value: string) => {
    const x = parseInt(value);
    if (x) {
      props.setNewIndex(clampValue(x) - 1);
    }
  };

  const showDate = () => {
    return <span>{props.currentDate!.toLocaleDateString()}</span>;
  };

  const showEditableText = () => {
    return (
      <input
        type="text"
        disabled={props.showDate}
        value={props.currentIndex! + 1}
        onChange={(e) => setNewIndexWithSensibleValues(e.target.value)}
      />
    );
  };

  return (
    <div>
      <span onClick={(_) => reset()}>{String.fromCharCode(171)}</span>
      <span onClick={(_) => decrease()}>&lt;</span>
      {props.showDate ? showDate() : showEditableText()}
      {props.showDate ? `(${props.counter})` : ""}
      <span onClick={(_) => increase()}>&gt;</span>
      <span onClick={(_) => toEnd()}>{String.fromCharCode(187)}</span>
    </div>
  );
};
