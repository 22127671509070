import './App.css';

import React from 'react';

import { BackendMessage, buildFromRepoOptions } from './BackendMessage';
import { MainWindowComponent } from './components/MainWindowComponent';
import { ProgressBar } from './components/progressBars/ProgressBar';
import { ProgressTracker, TrackerOptions } from './components/progressBars/ProgressTrackerComponent';
import { SettingsModal, SettingsProps } from './components/settings/SettingsModal';
import { DefaultSettingsOptions, SettingsOptions } from './components/settings/SettingsOptions';
import { SplashScreen } from './components/SplashScreen';
import { IIndexedNodeData, IMetadata } from './components/types/types';
import { createIndexedNodesFromBackendNodes, setupColors, setupDeltaInfoForNodes } from './utils/nodes';
import { AvailableStatus, BackendStatus, statusMapping } from './utils/StatusMapping';
import { beautifyGitRepoName, buildRepositories } from './utils/utils';

interface IState {
  commitData: IIndexedNodeData[];
  metadata: IMetadata[];
  status: AvailableStatus;
  progressBar?: TrackerOptions;
  options: SettingsOptions;
}

const DefaultState: IState = {
  commitData: [],
  metadata: [],
  status: "READY",
  options: DefaultSettingsOptions,
};

class App extends React.Component<{}, IState> {
  state: IState = DefaultState;

  private BACKEND_URI= (process.env.REACT_APP_BACKEND || "ws://127.0.0.1:9291")

  private websocketBackendComponent = new WebSocket(this.BACKEND_URI);

  
  componentDidMount() {
    document.addEventListener("contextmenu", function (event: any) {
      event.preventDefault();
    });


    this.websocketBackendComponent.onopen = () => {
      console.log("Connected to backend");
 //     this.keepAlive();
    };

    this.websocketBackendComponent.onmessage = (evt: any) => {
      const message = JSON.parse(evt.data);
      if (message.operation === "AVAILABLEREPOS") {
        const repositories = buildRepositories(JSON.parse(message.payload));
        this.setState((prevState) => ({
          ...prevState,
          options: { ...prevState.options, repositories },
        }));
      } else {
        this.setCurrentStateFrom(message);
      }
    };

    this.websocketBackendComponent.onclose = () => {
      console.log("Disconnected from backend");
   //   this.cancelKeepAlive();
    };
  }

  render() {
    return (
      <div style={{ padding: "15px", position: "relative", zIndex: 0 }}>
        {this.buildSettingsModal()}
        {this.drawMainScreen()}
      </div>
    );
  }

  private buildSettingsModal = () => {
    return (
      <div
        className="InputArea"
        style={{ textAlign: "right", marginRight: "50px" }}
      >
        <SettingsModal {...this.buildRepoDataProps()} />
      </div>
    );
  };

  private enabledFromStatus = (status: AvailableStatus) => {
    return ["READY", "COMPLETED"].includes(status);
  };

  private drawMainScreen = () => {
    switch (this.state.status) {
      case "GITSHOW":
      case "BUILDMETRICTREE": {
        const progressBar = this.state.progressBar!;
        return (
          <ProgressTracker
            name={progressBar.name}
            index={progressBar.index}
            total={progressBar.total}
          />
        );
      }
      case "ALGORITHMSTARTED": {
        return <ProgressBar text="Algorithm started"></ProgressBar>;
      }
      case "DOWNLOADING": {
        return (
          <ProgressBar text="Downloading data from remote repository"></ProgressBar>
        );
      }
      case "COMPLETED":
      case "RUNNING": {
        if (this.state.commitData.length > 0) {
          return this.drawMainWindow();
        } else {
          return "";
        }
      }
      case "READY": {
        return <SplashScreen />;
      }
      default: {
        console.log(`Received unmanaged operation ${this.state.status}`);
        return "";
      }
    }
  };

  private drawMainWindow = () => {
    return (
      <MainWindowComponent
        title={beautifyGitRepoName(this.state.options.repository)}
        commitData={this.state.commitData}
        metadata={this.state.metadata}
        boundingBox={this.state.options.boundingBox}
        completed={this.state.status === "COMPLETED"}
        showDate={this.state.options.filteringOptions.byDateGrouping !== undefined}
        grouping={this.state.options.filteringOptions.byDateGrouping}
      />
    );
  };

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // functions to work with the Settings modal
  private buildRepoDataProps = (): SettingsProps => {
    return {
      statusDisabled: !this.enabledFromStatus(this.state.status),
      options: this.state.options,
      setOptions: this.setOptions,
      onClickedProcessingButton: this.startProcessing,
    };
  };

  private setOptions = (options: SettingsOptions): void => {
    this.setState((prevState) => {
      return { ...prevState, options };
    });
  };

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Websocket Related stuff
  private setCurrentStateFrom(message: any) {
    const operation: BackendStatus = message.operation;
    const newStatus = statusMapping[operation];
    switch (newStatus) {
      case "GITSHOW": {
        this.setupTracker(
          newStatus,
          "Extracting commit data from source",
          message.index,
          message.total
        );
        break;
      }
      case "BUILDMETRICTREE": {
        this.setupTracker(
          newStatus,
          "Building diff tree structure",
          message.payload.index,
          message.payload.total
        );
        break;
      }
      case "RUNNING": {
        this.addNewDataFromPayload(message.payload);
        break;
      }
      default: {
        this.setState((prevState) => ({ ...prevState, status: newStatus }));
        break;
      }
    }
  }

  private setupTracker = (
    newStatus: AvailableStatus,
    name: string,
    index: number,
    total: number
  ) => {
    this.setState((prevState) => ({
      ...prevState,
      status: newStatus,
      progressBar: { name, index, total },
    }));
  };

  private addNewDataFromPayload = (payload: any) => {
    const { tree_data, metadata } = payload;
    const { nodes } = tree_data;
    const newCommitNodes = createIndexedNodesFromBackendNodes(
      Object.values(nodes)
    );
    this.setState((prevState) => {
      const lastCommitNodes =
        prevState.commitData[prevState.commitData.length - 1];
      setupDeltaInfoForNodes(newCommitNodes, lastCommitNodes || {});
      setupColors(newCommitNodes, metadata.colors);
      const newState: IState = {
        ...prevState,
        progressBar: undefined,
        status: "RUNNING",
      };
      newState.commitData.push(newCommitNodes);
      newState.metadata.push({
        repository: metadata.remote_repo,
        maxLevel: metadata.max_level,
        commitHash: metadata.commit_hash,
        date: new Date(metadata.date),
        commitMessage: metadata.message,
        colors: metadata.colors,
      });
      return newState;
    });
  };

  private startProcessing = () => {
    this.resetTreeDataState();
    this.websocketBackendComponent.send(
      JSON.stringify(this.buildMessageForBackend(this.state))
    );
  };

  private buildMessageForBackend = (state: IState): BackendMessage =>
    buildFromRepoOptions(state.options);
  private resetTreeDataState = () =>
    this.setState((prevState) => ({
      ...prevState,
      commitData: [],
      metadata: [],
      currentIndex: 0,
    }));
}

export default App;
