import React from "react";

import { HeaderStyle } from "../SettingsModal";
import { ByTimeElementsChooser, ByTimeProps } from "./ByTimeElementsChooser";
import {
  LastElementsChooser,
  LastElementsChooserProps,
} from "./LastElementsChooser";
import { SamplingComponent, SamplingProps } from "./SamplingComponent";

export interface SeriesFilteringProps {
  samplingOptions: SamplingProps;
  lastElementsOptions: LastElementsChooserProps;
  byTimeOptions: ByTimeProps;
}

export const SeriesFilteringPopup = (props: SeriesFilteringProps) => {
  return (
    <>
      <div style={HeaderStyle}>Simulation Filtering Options</div>
      <SamplingComponent {...props.samplingOptions} />
      <br />
      <LastElementsChooser {...props.lastElementsOptions} />
      <br />
      <ByTimeElementsChooser {...props.byTimeOptions} />
    </>
  );
};
