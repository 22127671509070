import React from "react";

import { IMetadata } from "./types/types";
import { formatForTitle } from "../utils/utils";

interface TitleProps {
  name: string;
}

export const TitleComponent = (props: TitleProps) => {
  return (
    <div style={{ fontSize: "40px", fontWeight: "bold", textAlign: "center" }}>
      {formatForTitle(props.name)}
    </div>
  );
};

interface MetadataProps {
  metadata: IMetadata;
  rootWeight: number;
  deltaWeight: number;
}

export const MetadataInformationComponent = (props: MetadataProps) => {
  const theDate = new Date(props.metadata.date);
  return (
    <div
      style={{
        width: "auto",
        border: "black 1px solid",
        borderRadius: "3pt",
        backgroundColor: "#F0F0F0",
      }}
    >
      <div>Commit Hash:{props.metadata.commitHash}</div>
      <div>Date: {theDate.toLocaleString()}</div>
      <div>Commit Message: {props.metadata.commitMessage}</div>
      <div>
        Overall Size: {props.rootWeight} ({props.deltaWeight > 0 ? "+" : ""}
        {props.deltaWeight})
      </div>
    </div>
  );
};
