import { IColor, IIndexedColors, IIndexedNodeData, INodeData, IVoronoiCell } from '../components/types/types';
import { NodeBoundingBox } from './cameras';
import { minMax, snakeToCamel } from './utils';

//setup nodes coming from ws so that they can be indexed
export const createIndexedNodesFromBackendNodes = (
  nodes: INodeData[]
): IIndexedNodeData => {
  const newNodes = convertNodesStructure(nodes);
  fillBranchIndex(newNodes);
  return newNodes;
};

//for delta mode, we want to have the delta in metric between the last commit and the new one
export const setupDeltaInfoForNodes = (
  newNodes: IIndexedNodeData,
  previousNodes: IIndexedNodeData
): void => {
  Object.entries(newNodes).forEach(([key, node]) => {
    setupDeltaWeight(node, previousNodes[key]);
    if (node.leaf) {
      setupHistoricalData(newNodes, previousNodes, node, previousNodes[key]);
    }
  });
};

export const setupColors = (
  nodes: IIndexedNodeData,
  colors: IIndexedColors
): void => {
  Object.entries(colors).forEach(([key, color]) => {
    setupColorsOnDescendants(nodes, key, color);
  });
};

const setupColorsOnDescendants = (
  nodes: IIndexedNodeData,
  key: string,
  color: IColor
) => {
  const actualNode = nodes[key];
  actualNode.color = color;
  actualNode.children.forEach((name) => {
    if (name !== key) {
      setupColorsOnDescendants(nodes, name, color);
    }
  });
};

const setupDeltaWeight = (
  node: INodeData,
  previousNode: INodeData | undefined
) => {
  node.deltaInfo!.deltaWeight =
    node.weight - (previousNode ? previousNode.weight : 0);
};

const setupHistoricalData = (
  nodes: IIndexedNodeData,
  previousNodes: IIndexedNodeData | undefined,
  node: INodeData,
  previousNode: INodeData | undefined
) => {
  const deltaInfo = node.deltaInfo!;
  if (deltaInfo.deltaWeight !== 0) {
    deltaInfo.epochsSinceModification = 0;
    deltaInfo.lastModificationType =
      deltaInfo.deltaWeight > 0 ? "increase" : "decrease";
  } else {
    if (node.modified) {
      deltaInfo.epochsSinceModification = 0;
      deltaInfo.lastModificationType = "change";
    } else {
      if (previousNode) {
        deltaInfo.lastModificationType = previousNode.deltaInfo!.lastModificationType;
        deltaInfo.epochsSinceModification =
          previousNode.deltaInfo!.epochsSinceModification + 1;
      } else {
        deltaInfo.epochsSinceModification = 0;
      }
    }
  }
  if (node.parent) {
    propagateToParentEpoch(
      nodes,
      nodes[node.parent],
      previousNodes,
      node.deltaInfo!.epochsSinceModification
    );
  }
};

const propagateToParentEpoch = (
  nodes: IIndexedNodeData,
  node: INodeData,
  previousNodes: IIndexedNodeData | undefined,
  newValue: number
) => {
  const deltaInfo = node.deltaInfo!;
  if (deltaInfo.deltaWeight !== 0) {
    deltaInfo.epochsSinceModification = 0;
    deltaInfo.lastModificationType =
      deltaInfo.deltaWeight > 0 ? "increase" : "decrease";
  } else {
    const previousNode = previousNodes ? previousNodes[node.name] : undefined;
    if (previousNode) {
      deltaInfo.epochsSinceModification = Math.min(
        newValue,
        previousNode.deltaInfo!.epochsSinceModification + 1
      );
    } else {
      deltaInfo.epochsSinceModification = 0;
    }
  }
  if (node.parent) {
    propagateToParentEpoch(
      nodes,
      nodes[node.parent],
      previousNodes,
      deltaInfo.epochsSinceModification
    );
  }
};

// Given a set of indexed-by-name nodes, it fills up the level at which
// each node is in the current branch hierarchy.
const fillBranchIndex = (nodes: IIndexedNodeData) => {
  const leaves = Object.values(nodes).filter((values: INodeData) => {
    return values.leaf === true;
  });
  leaves.forEach((x: INodeData) => {
    x.branchIndex = 1;
  });
  leaves.forEach((leaf: INodeData) => updateParent(nodes, leaf.parent, 2));
};

const convertNodesStructure = (nodes: INodeData[]): IIndexedNodeData => {
  return nodes.reduce((mem: IIndexedNodeData, node: INodeData) => {
    mem[node.name] = convertNode(node);
    return mem;
  }, {});
};

const convertNode = (node: INodeData): INodeData => {
  return Object.entries(node).reduce(
    (mem: INodeData, val) => {
      // @ts-ignore
      mem[snakeToCamel(val[0])] = val[1];
      if (val[0] === "children") {
        mem["leaf"] = val[1].length === 1;
      }
      return mem;
    },
    {
      deltaInfo: { deltaWeight: 0, epochsSinceModification: 9999 },
    } as INodeData
  );
};

const updateParent = (
  nodes: IIndexedNodeData,
  parent: string | undefined,
  newIndex: number
) => {
  if (!parent) {
    return;
  }
  const parentNode = nodes[parent];
  if (!parentNode.branchIndex || parentNode.branchIndex < newIndex) {
    parentNode.branchIndex = newIndex;
    updateParent(nodes, parentNode.parent, newIndex + 1);
  }
};

export const buildBoundingBox = (cell: IVoronoiCell): NodeBoundingBox => {
  const points = cell.polygon.points;
  const x = minMax(points.map((p) => p.x));
  const y = minMax(points.map((p) => p.y));
  return { xMin: x[0], yMin: y[0], xMax: x[1], yMax: y[1] };
};
