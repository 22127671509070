import { RectangularStyledButton } from "../fancyComponents/buttonStyle";

export interface StartProcessingButtonProps {
  disabled: boolean;
  onClickedProcessingButton: () => void;
}

export const StartProcessingButton = (props: StartProcessingButtonProps) => {
  return (
    <button
      disabled={props.disabled}
      style={RectangularStyledButton}
      onClick={(e) => props.onClickedProcessingButton()}
    >
      Start Processing
    </button>
  );
};
