import React from "react";
import Popup from "reactjs-popup";
import { circularStyledButton } from "../fancyComponents/buttonStyle";

export interface SearchProps {
  pattern: string;
  setPattern: (pattern: string) => void;
}

export const SearchButton = (props: SearchProps) => {
  return (
    <Popup
      trigger={
        <button
          style={{
            ...circularStyledButton,
            backgroundColor: "lightgray",
            position: "absolute",
            zIndex: 1,
          }}
        >
          S
        </button>
      }
      contentStyle={{ width: "auto" }}
      position="right top"
    >
      <input
        type="text"
        value={props.pattern}
        onChange={(e) => props.setPattern(e.currentTarget.value)}
      />
    </Popup>
  );
};
