import React from "react";

export interface LastElementsChooserProps {
  chooserOptions: {
    enabled: boolean;
    amount?: number;
  };
  setEnabled: (check: boolean) => void;
  setAmount: (lastN: number) => void;
}

export const LastElementsChooser = (props: LastElementsChooserProps) => {
  return (
    <label>
      <input
        type="checkbox"
        checked={props.chooserOptions.enabled}
        onChange={(e) => props.setEnabled(e.target.checked)}
      />
      Analyze only last
      <input
        type="text"
        value={props.chooserOptions.amount}
        size={3}
        maxLength={3}
        onChange={(e) => props.setAmount(parseInt(e.target.value))}
      />
      commits
    </label>
  );
};
