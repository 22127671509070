import { Repositories } from "../components/settings/SettingsOptions";

export function each_cons_closed<P>(array: P[], size: number): P[][] {
  var offset = 0;
  var result = [];
  var new_array = [...array, array[0]];
  while (offset < array.length) {
    result.push(new_array.slice(offset, offset + size));
    offset += 1;
  }
  return result;
}

export function clamp(a: number, b: number, c: number): number {
  return [a, b, c].sort(function (a, b) {
    return a - b;
  })[1];
}

export function minMax<P>(items: P[]) {
  return items.reduce((acc, val) => {
    acc[0] = acc[0] === undefined || val < acc[0] ? val : acc[0];
    acc[1] = acc[1] === undefined || val > acc[1] ? val : acc[1];
    return acc;
  }, [] as P[]);
}

export function snakeToCamel(theString: string): string {
  return theString.replace(/_(.)/g, (a, b) => {
    return b.toUpperCase();
  });
}

export function buildRepositories(array: any[]): Repositories {
  return array.map((elem : any) => { return {name: elem["name"], remoteRepo: elem["remote_repo"]}})  
}

export function beautifyGitRepoName(repoName: string): string {
  const repoArray = repoName.split("/");
  return repoArray[repoArray.length - 1].replace(".git", "");
}

function capitalizeFirstLetter(word: string): string {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export function formatForTitle(bareRepoTitle: string): string {
  return bareRepoTitle
    .split("-")
    .map((word) => capitalizeFirstLetter(word))
    .join(" ");
}

export function parseNodeName(fullName: string): string {
  const splittedBySlash = fullName.split("/");
  return splittedBySlash[splittedBySlash.length - 1];
}

function onlyUnique<T>(value: any, index: any, self: T[]) {
  return self.indexOf(value) === index;
}

export function distinct<T>(arr: T[]) {
  return arr.filter(onlyUnique);
}
