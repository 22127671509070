import React from "react";

import splashScreen from "../assets/voro_splash.png";

export const SplashScreen = () => {
  return (
    <div
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",
      }}
    >
      <img src={splashScreen} alt="Voronoi Evolving Treemap" />
    </div>
  );
};
