import React, { CSSProperties, useState, useEffect } from "react";
import { Circle } from "rc-progress";

export interface ProgressBarProps {
  text: string;
}

export const ProgressBar = (props: ProgressBarProps) => {
  const { text } = props;
  const [ticker, setTicker] = useState(0);

  useEffect(() => {
    const timeoutId = setInterval(() => {
      setTicker((ticker) => (ticker + 4) % 100);
    }, 250);
    return () => {
      clearInterval(timeoutId);
    };
  }, []);

  return (
    <div style={asSplashScreenStyle}>
      {text}
      <div>
        <Circle
          style={{ height: "50px", width: "50px" }}
          percent={ticker}
          strokeWidth={8}
          strokeColor="#343434"
        />
      </div>
    </div>
  );
};

const asSplashScreenStyle: CSSProperties = {
  // position: "fixed",
  backgroundColor: "lightgrey",
  color: "black",
  width: "450px",
  height: "150px",
  position: "absolute",
  top: "0",
  bottom: "0",
  left: "0",
  right: "0",
  margin: "auto",
  padding: "50px",
  border: "4px solid black",
  fontSize: "20px",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  verticalAlign: "middle",
};
