export const circularStyledButton = {
  backgroundColor: "#BBBBBB",
  borderRadius: "50%",
  fontSize: "20px",
  border: "2px solid #444444",
  width: "30px",
};

export const RectangularStyledButton = {
  backgroundColor: "#4CAF50",
  borderRadius: "3px",
  fontSize: "16px",
  color: "#333333",
  border: "2px solid #444444",
  height: "40px",
  width: "180px",
};
