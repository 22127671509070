import React from "react";
import { Vector3 } from "three";

import FatLine from "../DrawingComponents/FatLine";
import { IPolygon } from "../types/types";

export interface IPolygonProps extends IPolygon {
  level: number;
  selected: boolean;
}

export const PerimeterComponent = (props: IPolygonProps) => {
  const positions: Vector3[] = props.polygon.points.map(
    (p) => new Vector3(p.x, p.y, props.selected ? 1.0 : 0.0)
  );
  const defaultWidth = props.level;
  const points = [...positions, positions[0]];
  const color = props.selected ? "red" : "black";
  const width = defaultWidth + (props.selected ? 3 : 0);
  return <FatLine points={points} width={width} color={color} />;
};

export default PerimeterComponent;
