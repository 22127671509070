import { Html } from "@react-three/drei";
import React, { CSSProperties } from "react";

import { nodeHierarchyInfo, nodeInfoGetter } from "./VoronoiCell";

const popupStyle: CSSProperties = {
  fontSize: "14px",
  backgroundColor: "lightgrey",
  borderRadius: "4pt",
  boxShadow: "3px 3px #888888",
  padding: "10px",
};

const headerStyle: CSSProperties = {
  fontWeight: "bold",
  fontSize: "16px",
  textAlign: "center",
};

interface PopupProps {
  name: string;
  weight: number;
  delta: number;
  getter: nodeInfoGetter;
}


export const InformativePopup = (props: PopupProps) => {
  const prettifyName = (name: String) => {
     if (name==="$root") {
       return "/"
     }
     else
     return name.replace(/^\$root/, "")
  }


  const formatData = (data: nodeHierarchyInfo) =>
    data.map((singleElement) => {
      return (
        <div key={singleElement.name}>
          <span style={{ fontWeight: "bold" }}>{prettifyName(singleElement.name)}</span>:
          <span>{(100 * singleElement.basePercentage).toFixed(3) + "%"}</span>
        </div>
      );
    });

  const weight = () => {
    return (
      <div>
        <span style={{ fontWeight: "bold" }}>Weight</span>:
        <span>
          {props.weight} ({props.delta > 0 ? "+" : ""}
          {props.delta})
        </span>
      </div>
    );
  };

  const header = () => {
    return <div style={headerStyle}>{prettifyName(props.name)}</div>;
  };

  return (
    <Html>
      <div style={popupStyle}>
        {header()}
        <hr />
        {weight()}
        <hr />
        {formatData(props.getter(props.name))}
      </div>
    </Html>
  );
};
