import React, { useState } from "react";
import { IIndexedNodeData, INodeData } from "../types/types";

export interface TopNProps {
  nodes: IIndexedNodeData;
  setSelectedPolygon: (nodeName?: string) => void;
}

export const TopNWindow = (props: TopNProps) => {
  const [nodeLevel, setNodeLevel] = useState(1);
  const [numberOfResults, setNumberOfResults] = useState(5);

  const displaySimpleNode = (node: INodeData) => {
    return (
      <div
        key={node.name}
        onMouseEnter={(_) => props.setSelectedPolygon(node.name)}
        onMouseLeave={(_) => props.setSelectedPolygon(undefined)}
      >
        <b>{node.name}:</b> {node.weight}
      </div>
    );
  };

  const showN = () => {
    const levelNodes = Object.values(props.nodes).filter(
      (node) => node.level === nodeLevel
    );
    return levelNodes
      .sort((n1, n2) => n2.weight - n1.weight)
      .slice(0, numberOfResults)
      .map((node) => displaySimpleNode(node));
  };

  return (
    <div>
      <div>
        <label>
          Show Node Level:
          <input
            type="text"
            value={nodeLevel}
            onChange={(e) => {
              setNodeLevel(parseInt(e.target.value));
            }}
          />
        </label>
      </div>
      <div>
        <label>
          Show Top N:
          <input
            type="text"
            value={numberOfResults}
            onChange={(e) => setNumberOfResults(parseInt(e.target.value))}
          />
        </label>
      </div>
      {showN()}
    </div>
  );
};
