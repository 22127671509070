import React from "react";
import { TogglerProps } from "../../types/types";

export interface AutoadvancingControllerProps extends TogglerProps {
  intervalDelay: number;
  setIntervalDelay: (newDelay: number) => void;
}

export const TimelapseController = (props: AutoadvancingControllerProps) => {
  return (
    <label>
      Start Timelapse
      <input
        type="checkbox"
        checked={props.checked}
        onChange={(e) => props.onToggle(e.target.checked)}
      />
      every
      <input
        type="text"
        value={props.intervalDelay}
        onChange={(e) => props.setIntervalDelay(parseInt(e.target.value))}
      />{" "}
      ms
    </label>
  );
};
