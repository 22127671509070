import { useState } from 'react';
import { Canvas } from 'react-three-fiber';

import { NodeCenteredCamera } from './NodeCenteredCamera';
import TreemapContainer from './TreemapComponent';
import { ISingleSweepProps } from './types/types';

interface CanvasComponentProps extends ISingleSweepProps {
  selectedPolygon?: string;
  selectedPattern?: string;
}

export const CanvasComponent = (props: CanvasComponentProps) => {
  const { data, metadata } = props;

  const [currentFocusedNode, setFocusedNode] = useState("$root");
  const [currentLevel, setCurrentLevel] = useState(1);

  const increaseLevel = () => setCurrentLevel(currentLevel+1)
  const decreaseLevel = () => setCurrentLevel(currentLevel-1)

  const getParentAtLevel = (name: string) : string => {
    return (data[name].level===currentLevel) ? name : getParentAtLevel(data[name].parent!)
  }

  const zoomStuff = (
    nodeName: string,
    zoomIn: boolean
  ) => {
    if (zoomIn) {
    if (data[nodeName].level>currentLevel) {
      increaseLevel()
    } }
    else {
      if (currentLevel>0 ) {
        decreaseLevel()
      }

    }
    const nodeToFocusOn = getParentAtLevel(nodeName)
    setFocusedNode(nodeToFocusOn);
  };

  return (
    <Canvas
      className="mainCanvas"
      resize={{ scroll: false }}
      style={{
        padding: 0,
        // margin: "auto",
        // border: "black 3px solid",
      }}
      invalidateFrameloop
      updateDefaultCamera={false}
    >
      <NodeCenteredCamera node={data[currentFocusedNode]} />
      {/* <Icosahedron key={"DUMMY"} position={[500,500,7]} args={[10, 10]}><meshBasicMaterial attach="material" color="red" wireframe /></Icosahedron> */}
      <TreemapContainer
        data={data}
        metadata={metadata}
        zoomOn={zoomStuff} //this.props.runtimeOptions.freeFlightMode ? this.doNothing :
        showLabels={props.runtimeOptions.showLabels}
        currentlyShownLevels={props.runtimeOptions.currentlyShownLevels}
        deltaMode={props.runtimeOptions.deltaMode}
        selectedPolygon={props.selectedPolygon}
        selectedPattern={props.selectedPattern}
      />
    </Canvas>
  );
};

export default CanvasComponent;
