import React from "react";
import CanvasComponent from "./CanvasComponent";
import { IndexChooser } from "./UIComponents/IndexChooser";
import { TitleComponent } from "./TitleComponent";
import {
  IIndexedNodeData,
  TogglerProps,
  IMetadata,
  BoundingBox,
} from "./types/types";
import { VisualizationPopup } from "./menus/VisualizationPopup";
import { DeltaButtonComponent } from "./menus/DeltaButtonComponent";
import { MetadataPopup } from "./popups/MetadataPopup";
import { TopNPopUp } from "./popups/TopNPopUp";
import { SearchButton } from "./popups/Search";
import { LevelSliderProps } from "./UIComponents/VisualizationSubComponents/LevelSlider";
import { AutoadvancingControllerProps } from "./UIComponents/VisualizationSubComponents/TimelapseController";
import { TimeSamplingOptions } from "../BackendMessage";

interface MainWindowProps {
  title: string;
  commitData: IIndexedNodeData[];
  metadata: IMetadata[];
  boundingBox: BoundingBox;
  completed: boolean;
  showDate: boolean;
  grouping: TimeSamplingOptions;
}

interface RuntimeOptions {
  deltaMode: boolean;
  freeFlightMode: boolean;
  currentlyShownLevels: number;
  showLabels: boolean;
}

const DefaultRuntimeOptions: RuntimeOptions = {
  freeFlightMode: false,
  showLabels: true,
  currentlyShownLevels: 0,
  deltaMode: false,
};

interface AutoadvancingStateVariables {
  intervalId? : number;
  delayMs: number;
}

interface WindowState {
  currentIndex: number;
  currentInstant: Date;
  runtimeOptions: RuntimeOptions;
  selectedPolygon?: string;
  searchPattern: string;
  cycling: AutoadvancingStateVariables;
  timelapse: AutoadvancingStateVariables;
}

export class MainWindowComponent extends React.Component<
  MainWindowProps,
  WindowState
> {
  
  constructor(props: MainWindowProps) {
    super(props);
    this.state = {
      currentIndex: 0,
      currentInstant: new Date,
      runtimeOptions: DefaultRuntimeOptions,
      cycling: { delayMs: 500 },
      timelapse: {delayMs: 500},
      searchPattern: "",
    };
  }

  componentDidMount = () => {
    this.setCurrentLevel(this.props.metadata[0].maxLevel);
    this.setState( (prevState) => {return {...prevState, currentInstant: this.props.metadata[0].date}})
    this.props.grouping===undefined ?
      this.toggleCycling(true) : 
      this.toggleTimelapse(true);
  };

  componentDidUpdate(prevProps: any, prevState: any) {
    // if we are drawing something, then we build the visible node list array
    // if we are in status completed, AND we are at the last treemap, stop cycling automatically
    if (
      this.state.currentIndex === this.props.commitData.length - 1 &&
      this.props.completed &&
      (this.state.cycling.intervalId || this.state.timelapse.intervalId)
    ) {
      this.toggleCycling(false);
      this.toggleTimelapse(false);
      this.setState(prevState => {return {...prevState, currentInstant: this.props.metadata[0].date}})
    }
  }

  private changeRuntimeOptions = <K extends keyof RuntimeOptions>(field: K) => (
    value: RuntimeOptions[K]
  ) => {
    const runtimeOptions = { ...this.state.runtimeOptions, [field]: value };
    this.setState((prevState) => {
      return { ...prevState, runtimeOptions };
    });
  };

  private buildDeltaProps = (): TogglerProps => {
    return {
      checked: this.state.runtimeOptions.deltaMode,
      onToggle: this.changeRuntimeOptions("deltaMode"),
    };
  };

  private buildVisualizationProps = () => {
    return {
      labelTogglerOptions: this.setShowLabels(),
      cyclingOptions: this.setCyclingOptions(),
      timelapseOptions: this.setTimelapseOptions(),
      levelInformation: this.setSliderOptions(),
      autoAdvanceMode: (this.props.grouping===undefined ? "cycling" : "timelapse" as "cycling" | "timelapse")
    };
  };

  private setSliderOptions = () : LevelSliderProps=> {
    return {        
      currentLevel: this.state.runtimeOptions.currentlyShownLevels,
      maxLevel: this.props.metadata[this.state.currentIndex].maxLevel,
      setLevel: this.setCurrentLevel,
    }
  } 

  private setIntervalDelay = (newDelay: number) => {
    const newCyclingOptions = { ...this.state.cycling, delayMs: newDelay}
    this.setState((prevState) => ({ ...prevState, cycling: newCyclingOptions }));
    this.toggleCycling(this.state.cycling.intervalId ? true : false);
  };

  private setTimelapseDelay = (newDelay: number) => {
    const newTimelapseOptions = { ...this.state.timelapse, delayMs: newDelay}
    this.setState((prevState) => ({ ...prevState, timelapse: newTimelapseOptions }));
    this.toggleTimelapse(this.state.timelapse.intervalId ? true : false);
  };

  private setShowLabels = (): TogglerProps => {
    return {
      checked: this.state.runtimeOptions.showLabels,
      onToggle: this.changeRuntimeOptions("showLabels"),
    };
  };

  private setCurrentLevel = (newLevel: number) => {
    this.changeRuntimeOptions("currentlyShownLevels")(newLevel);
  };

  private setCyclingOptions = (): AutoadvancingControllerProps => {
    return {
      checked: this.state.cycling.intervalId ? true : false,
      intervalDelay: this.state.cycling.delayMs,
      onToggle: this.toggleCycling,
      setIntervalDelay: this.setIntervalDelay,
    };
  };

  private setTimelapseOptions = (): AutoadvancingControllerProps => {
    return {
      checked: this.state.timelapse.intervalId ? true : false,
      intervalDelay: this.state.timelapse.delayMs,
      onToggle: this.toggleTimelapse,
      setIntervalDelay: this.setTimelapseDelay,
    };
  };


  render() {
    return (
      <div className="mainWindowArea" style={{ position: "relative" }}>
        {this.title()}
        <div
          className="canvasArea"
          style={{
            position: "relative",
            width: "90vw",
            height: "90vh",
            margin: "0 auto",
            textAlign: "center",
          }}
        >
          {this.canvas()}
          {this.indexchooser()}
          {this.overallMetadata()}
          {this.buttons()}
        </div>
      </div>
    );
  }

  private title = () => {
    return <TitleComponent name={this.props.title} />;
  };

  private canvas = () => {
    return (
      <CanvasComponent
        data={this.props.commitData[this.state.currentIndex]}
        boundingBox={this.props.boundingBox}
        metadata={this.props.metadata[this.state.currentIndex]}
        runtimeOptions={this.state.runtimeOptions}
        selectedPolygon={this.state.selectedPolygon}
        selectedPattern={this.state.searchPattern}
      />
    );
  };

  private indexchooser = () => {
    return (
      <IndexChooser
        showDate={this.props.showDate}
        currentIndex={this.state.currentIndex}
        currentDate={this.state.currentInstant} //this.props.metadata[this.state.currentIndex].date}
        counter={this.props.commitData.length}
        setNewIndex={this.setNewIndex}
      />
    );
  };

  private overallMetadata = () => {
    const shownRoot = this.props.commitData[this.state.currentIndex]["$root"];
    return (
      <div
        style={{ position: "absolute", top: "10px", left: "10px", zIndex: 3 }}
      >
        <MetadataPopup
          metadata={this.props.metadata[this.state.currentIndex]}
          rootWeight={shownRoot.weight}
          deltaRootWeight={shownRoot.deltaInfo!.deltaWeight}
        />
        <SearchButton
          pattern={this.state.searchPattern}
          setPattern={(searchPattern: string) =>
            this.setState((prevState) => ({ ...prevState, searchPattern }))
          }
        />
      </div>
    );
  };

  private buttons = () => {
    return (
      <div
        className="buttonSeries"
        style={{ position: "absolute", top: "5px", right: "5px" }}
      >
        <VisualizationPopup {...this.buildVisualizationProps()} />
        <DeltaButtonComponent {...this.buildDeltaProps()} />
        <TopNPopUp
          nodes={this.props.commitData[this.state.currentIndex]}
          setSelectedPolygon={this.setSelectedPolygon}
        />
      </div>
    );
  };

  private setSelectedPolygon = (nodeName?: string) => {
    this.setState((prevState) => {
      return { ...prevState, selectedPolygon: nodeName };
    });
  };

  private setNewIndex = (newIndex: number) => {
    this.setState((prevState) => {
      if (this.state.cycling.intervalId) {
        this.toggleCycling(false);
      }
      return { ...prevState, currentIndex: newIndex, currentInstant: this.props.metadata[newIndex].date};
    });
  };

  private toggleCycling = (startCycling: boolean) => {
    if (this.state.cycling.intervalId) {
      clearInterval(this.state.cycling.intervalId);
      this.toggleTimelapse(false)
    }
    if (startCycling) {
      const intervalId: number = setInterval(
        this.increaseNumberEvery,
        this.state.cycling.delayMs
      ) as any;
      this.setState((prevState) => {
        const cyclingOptions = { ...this.state.cycling, intervalId }
        return { ...prevState, cycling: cyclingOptions   };
      });
    } else {
      const cyclingOptions = { ...this.state.cycling, intervalId: undefined }
      this.setState((prevState) => {
        return { ...prevState, cycling: cyclingOptions };
      });
    }
  };

  private toggleTimelapse = (startRunning: boolean) => {
    if (this.state.timelapse.intervalId) {
       clearInterval(this.state.timelapse.intervalId);
       this.toggleCycling(false)
    }
    if (startRunning) {
      const intervalId: number = setInterval(
        this.increasePeriod,
        this.state.timelapse.delayMs
      ) as any;
      this.setState((prevState) => {
        const timelapseOptions = { ...this.state.timelapse, intervalId }
        return { ...prevState, timelapse: timelapseOptions};
      });
    } else {
      const timelapseOptions = { ...this.state.timelapse, intervalId: undefined }
      this.setState((prevState) => {
        return { ...prevState, timelapse: timelapseOptions };
      });
    }
  };


  private increaseNumberEvery = () => {
    if (this.props.commitData.length === 0) {
      return;
    }
    const newNumber = Math.min(
      this.state.currentIndex + 1,
      this.props.commitData.length - 1
    );
    if (newNumber !== this.props.commitData.length) {
      this.setState((prevState) => {
        return { ...prevState, currentIndex: newNumber };
      });
    }
  };


  private increaseTime = () : Date => {
    const currentlyMemoedDate = this.state.currentInstant
    switch(this.props.grouping) {
      case "BY_DAY": {
        const newDate = new Date(currentlyMemoedDate)
        newDate.setDate(currentlyMemoedDate.getDate()+1)
        return newDate
      }
      case "BY_WEEK": {
        const newDate = new Date(currentlyMemoedDate)
        newDate.setDate(currentlyMemoedDate.getDate()+7)
        return newDate
      }
      case "BY_MONTH": {
        const newDate = new Date(currentlyMemoedDate)
        newDate.setMonth(currentlyMemoedDate.getMonth()+1)
        return newDate
      }
      default:
        return currentlyMemoedDate
    }
  }


  private increasePeriod = () => {
    if (this.props.commitData.length === 0) {
      return;
    }
    this.setState( (prevState) => {return {...prevState, currentInstant: this.increaseTime()}})
    const newCandidates = this.props.metadata.filter( (elem,ix) => ix>=this.state.currentIndex && elem.date <= this.state.currentInstant )
    const newNumber = newCandidates.map(  (elem, ix)=> ix )[newCandidates.length-1]
    this.setState((prevState) => {
        return { ...prevState, currentIndex: this.state.currentIndex + newNumber };
      });
  };


}
