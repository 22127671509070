import { HeaderStyle } from "../SettingsModal";
import { NameFiltering } from "./NameFiltering";

export interface NameFilteringDefinerProps {
  nameFiltering: NameFiltering;
  onNameFilteringChange: (nf: NameFiltering) => void;
}

export const NameFilteringDefiner = (props: NameFilteringDefinerProps) => {
  const { exclude, includeOnly } = props.nameFiltering;

  const changeExclude = (fullString: string) => {
    const exclude = fullString.split(",");
    props.onNameFilteringChange({ ...props.nameFiltering, exclude });
  };

  const changeIncludeOnly = (fullString: string) => {
    const includeOnly = fullString.split(",");
    props.onNameFilteringChange({ ...props.nameFiltering, includeOnly });
  };

  const excludeTextBox = () => {
    return (
      <label>
        <span style={{ width: "20%" }}>Patterns to exclude:</span>
        <input
          style={{ width: "80%", float: "right" }}
          type="text"
          onChange={(event) => changeExclude(event.currentTarget.value)}
          value={exclude.join(", ")}
        />
      </label>
    );
  };

  const includeOnlyTextBox = () => {
    return (
      <label>
        <span style={{ width: "20%" }}>Patterns to include:</span>
        <input
          style={{ width: "80%", float: "right" }}
          type="text"
          onChange={(event) => changeIncludeOnly(event.currentTarget.value)}
          value={includeOnly.join(", ")}
        />
      </label>
    );
  };

  return (
    <>
      <div style={HeaderStyle}>Name Matching patterns</div>
      {includeOnlyTextBox()}
      <br />
      {excludeTextBox()}
      <br />
      <div style={{ fontSize: "small", fontStyle: "italic" }}>
        (insert elements separated by comma (,))
      </div>
    </>
  );
};
