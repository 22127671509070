import { Camera } from 'react-three-fiber';

import { BoundingBox, INodeData, ThreeDCoord } from '../components/types/types';
import { buildBoundingBox } from './nodes';

export interface ICurrentCamera {
  position: ThreeDCoord;
  frustum: Frustum;
  viewport?: { width: number; height: number };
}

export interface Frustum {
  left: number;
  right: number;
  top: number;
  bottom: number;
}

export interface NodeBoundingBox {
  xMin: number;
  xMax: number;
  yMin: number;
  yMax: number;
}


// the default camera is centered in the origin and 10 units in the z axis
export const DefaultCameraPosition: ThreeDCoord = [250, 250, 10];

export const createFrustumFromBoundingBox = (
  boundingBox: BoundingBox
): Frustum => {
  return {
    left: 0,
    bottom: 0,
    right: boundingBox.horizontal,
    top: boundingBox.vertical,
  };
};

export const resetCamera = (camera: Camera, frustum: Frustum): void => {
  setCamera(camera, frustum, DefaultCameraPosition);
};

export const setCamera = (
  camera: Camera,
  frustum: Frustum,
  position: ThreeDCoord
): void => {
  if (camera.type === "OrthographicCamera") {
    camera.position.set(...position);
    camera.left = frustum.left;
    camera.right = frustum.right;
    camera.bottom = frustum.bottom;
    camera.top = frustum.top;
    camera.updateProjectionMatrix();
  }
};

interface Size2D {
  width: number;
  height: number;
}

export const computeNewCameraCoords = (
  windowSizes: Size2D,
  targetNode: INodeData
): Frustum => {
  const currentWHRatio = windowSizes.width / windowSizes.height;
  const voronoiData = targetNode.voronoiObject;
  const boundingBox = buildBoundingBox(voronoiData);
  return stretchBoundaryToRatio(boundingBox, currentWHRatio);
};

const stretchBoundaryToRatio = (
  boundingBox: NodeBoundingBox,
  desiredRatio: number
): Frustum => {
  const boundingBoxCenter = {
    x: (boundingBox.xMax + boundingBox.xMin) / 2,
    y: (boundingBox.yMax + boundingBox.yMin) / 2,
  };
  const currentHeight = boundingBox.yMax - boundingBox.yMin;
  const currentWidth = boundingBox.xMax - boundingBox.xMin;
  const polyCurrentRatio = currentWidth / currentHeight;
  const newBoundingBox = { ...boundingBox };
  if (polyCurrentRatio > desiredRatio) {
    const new_height = currentWidth / desiredRatio;
    newBoundingBox.yMax = boundingBoxCenter.y + new_height / 2;
    newBoundingBox.yMin = boundingBoxCenter.y - new_height / 2;
  } else {
    const new_width = currentHeight * desiredRatio;
    newBoundingBox.xMax = boundingBoxCenter.x + new_width / 2;
    newBoundingBox.xMin = boundingBoxCenter.x - new_width / 2;
  }
  return {
    left: newBoundingBox.xMin - boundingBoxCenter.x / 2,
    right: newBoundingBox.xMax - boundingBoxCenter.x / 2,
    top: newBoundingBox.yMax - boundingBoxCenter.y / 2,
    bottom: newBoundingBox.yMin - boundingBoxCenter.y / 2,
  };
};
