import React from "react";

import { TogglerProps } from "../types/types";
import { circularStyledButton } from "../fancyComponents/buttonStyle";

export const DeltaButtonComponent = (props: TogglerProps) => {
  const selectedColor = () => {
    return props.checked ? "darkgray" : "lightgray";
  };

  return (
    <button
      title="Delta Mode"
      style={{ ...circularStyledButton, backgroundColor: selectedColor() }}
      onClick={() => props.onToggle(!props.checked)}
    >
      D
    </button>
  );
};
