import { BoundingBox } from "../types/types";
import {
  EmptyNameFiltering,
  NameFiltering,
} from "./nameFilteringDefiner/NameFiltering";
import { DefaultBoundingBox } from "./runningOptions/BoundingBoxSelectorComponent";
import {
  DefaultSeriesFilteringOptions,
  SeriesFilteringOptions,
} from "./seriesFiltering/SeriesFilteringOptions";

export interface Repository {
  name: string;
  remoteRepo: string;
}

export type Repositories = Repository[];
export type Metrics = string[];

export interface SettingsOptions {
  repositories: Repositories;
  repository: string; //contains the URL of the repository to analyze
  boundingBox: BoundingBox;
  shrinking: boolean;
  filteringOptions: SeriesFilteringOptions;
  metrics: Metrics;
  metric: string;
  nameFiltering: NameFiltering;
}

export const DefaultSettingsOptions: SettingsOptions = {
  repositories: [],
  repository:
    "NOTHING",
  boundingBox: DefaultBoundingBox,
  shrinking: false,
  filteringOptions: DefaultSeriesFilteringOptions,
  metric: "FILE_SIZE",
  metrics: ["FILE_SIZE", "DUMMY"],
  nameFiltering: EmptyNameFiltering,
};
