// @ts-nocheck
import React from "react";
import { extend } from "react-three-fiber";
import { Vector3 } from "three";
import { MeshLine, MeshLineMaterial, MeshLineRaycast } from "three.meshline";

extend({ MeshLine, MeshLineMaterial });

export interface IFatLineProps {
  points: Vector3[];
  color: string;
  width: number;
}

export const FatLine = (props: IFatLineProps) => {
  return (
    <mesh raycast={MeshLineRaycast}>
      <meshLine attach="geometry" points={props.points} />
      <meshLineMaterial
        attach="material"
        transparent
        depthTest={true}
        lineWidth={props.width}
        color={props.color}
      />
    </mesh>
  );
};

export default FatLine;
