import { OrthographicCamera } from '@react-three/drei';
import { useThree, ViewportData } from 'react-three-fiber';

import { computeNewCameraCoords, Frustum } from '../utils/cameras';
import { INodeData } from './types/types';

interface CameraProps {
  node: INodeData;
}



export const NodeCenteredCamera = (props: CameraProps) => {
  const { viewport } = useThree();
  const getCenter = (frustum: Frustum) : [number, number, number] => {
    const DefaultCameraHeight = 15;
    return [
       (frustum.left + frustum.right) / 2,
       (frustum.top + frustum.bottom) / 2,
       DefaultCameraHeight
    ]
  }

  const buildCamera = (frustum : Frustum) => {
    return <OrthographicCamera
      position={getCenter(frustum)}
      {...frustum}
      makeDefault
      // onUpdate={self => self.updateProjectionMatrix()}
    />      
  }

  const setBaseCamera = (viewport : ViewportData, node: INodeData) => {
    const defaultFrustum = {
        left: -viewport.width/2 + node.voronoiObject.site.x/2,
         bottom:-viewport.height/2 + node.voronoiObject.site.y/2, 
         right: viewport.width/2+ node.voronoiObject.site.x/2, 
         top: viewport.height/2 + node.voronoiObject.site.y/2}   
      return buildCamera(defaultFrustum)
    }

  const setNodeCamera = (viewport : ViewportData, node: INodeData) => {
     const frustum = computeNewCameraCoords(viewport, node)
     return buildCamera(frustum)
  }
  return props.node.name === "$root" ? setBaseCamera(viewport, props.node) : setNodeCamera(viewport, props.node)
};
