import React from "react";
import Popup from "reactjs-popup";

import { TopNWindow, TopNProps } from "../menus/TopNWindow";
import { circularStyledButton } from "../fancyComponents/buttonStyle";

export const TopNPopUp = (props: TopNProps) => {
  return (
    <Popup
      trigger={
        <button
          style={{ ...circularStyledButton, backgroundColor: "lightgray" }}
        >
          T
        </button>
      }
      contentStyle={{ width: "auto" }}
      position="left center"
    >
      <TopNWindow
        nodes={props.nodes}
        setSelectedPolygon={props.setSelectedPolygon}
      />
    </Popup>
  );
};
