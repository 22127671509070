import React from "react";
import { TimeSamplingOptions } from "../../../BackendMessage";

export interface ByTimeProps {
  currentValue: string | undefined;
  setValue: (value: TimeSamplingOptions) => void
} 

export const ByTimeElementsChooser = (props: ByTimeProps) => {
  return (
    <label>
      Group Commits:
      <select value={props.currentValue} onChange={e => props.setValue( e.target.value as TimeSamplingOptions)}>
        <option value={undefined}>No</option>
        <option value="BY_DAY">By day</option>
        <option value="BY_WEEK">By week</option>
        <option value="BY_MONTH">By month</option>
      </select>
    </label>
  );
};
