import React from "react";

import { BoundingBox } from "../../types/types";

export interface BoundingBoxProps {
  boundingBox: BoundingBox;
  onChangeBoundingBox: (boundingBox: BoundingBox) => void;
}

export const DefaultBoundingBox = {
  horizontal: 500, //window.innerWidth / 3 + 30,//300,
  vertical: 500, //window.innerHeight / 2 + 30//300
};

export const BoundingBoxSelectorComponent = (props: BoundingBoxProps) => {
  const horizontalRadiusTextBox = () => {
    return (
      <label style={{ marginRight: "5px" }}>
        Horizontal
        <input
          type="text"
          value={props.boundingBox.horizontal}
          onChange={(value) => {
            props.onChangeBoundingBox({
              horizontal: Number(value.target.value),
              vertical: props.boundingBox.vertical,
            });
          }}
        />
      </label>
    );
  };

  const verticalRadiusTextBox = () => {
    return (
      <label style={{ marginLeft: "20px" }}>
        Vertical
        <input
          type="text"
          value={props.boundingBox.vertical}
          onChange={(value) => {
            props.onChangeBoundingBox({
              horizontal: props.boundingBox.horizontal,
              vertical: Number(value.target.value),
            });
          }}
        />
      </label>
    );
  };

  return (
    <>
      Bounding Box Radius Options:
      <br />
      {horizontalRadiusTextBox()}
      {verticalRadiusTextBox()}
    </>
  );
};
