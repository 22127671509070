import { TimeSamplingOptions } from "../../../BackendMessage";

export interface SeriesFilteringOptions {
  samplingOptions: {
    enabled: boolean;
    frequency?: number;
  };
  lastElementOptions: {
    enabled: boolean;
    amount?: number;
  };
  byDateGrouping: TimeSamplingOptions;
}

export const DefaultSeriesFilteringOptions: SeriesFilteringOptions = {
  samplingOptions: {
    enabled: false,
    frequency: undefined,
  },
  lastElementOptions: {
    enabled: true,
    amount: 10,
  },
  byDateGrouping: undefined,
};
