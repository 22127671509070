import React from "react";

export interface SamplingProps {
  samplingOptions: {
    enabled: boolean;
    frequency?: number;
  };
  setSampling: (check: boolean) => void;
  setFrequency: (everyN: number) => void;
}

export const SamplingComponent = (props: SamplingProps) => {
  return (
    <label>
      <input
        type="checkbox"
        checked={props.samplingOptions.enabled}
        onChange={(e) => props.setSampling(e.target.checked)}
      />
      Sampling every
      <input
        type="text"
        value={props.samplingOptions.frequency}
        size={3}
        maxLength={3}
        onChange={(e) => props.setFrequency(parseInt(e.target.value))}
      />
      commit
    </label>
  );
};
