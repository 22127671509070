export interface LevelSliderProps {
    currentLevel: number;
    maxLevel: number
    setLevel: (level: number) => void;
  }

const sliderDefaultStyle = {width:"100%",height:"25px",background:"#d3d3d3",outline:"none",opacity:"0.7",WebkitTransition:".2s",transition:"opacity .2s"}

  
export const LevelSlider = (props: LevelSliderProps) => {
    return (
      <div>
        Show node hierarchy
        <input type="range" min="1" max={props.maxLevel} value={props.currentLevel} onChange={(newLevel) => props.setLevel(newLevel.target.valueAsNumber)}  style={sliderDefaultStyle}></input>
      </div>
    );
  };

