import { HeaderStyle } from "../SettingsModal";
import { Repositories, Repository } from "../SettingsOptions";

export interface RepositorySelectorProps {
  repositories: Repositories;
  onSelectChange: (shortRepoName: string) => void;
  onInputChange: (fullRepoUrl: string) => void;
}

export const RepositorySelector = (props: RepositorySelectorProps) => {
  const buildRepositoryOption = (repository: Repository) => {
    return (
      <option key={repository.name} value={repository.remoteRepo}>
        {repository.name}
      </option>
    );
  };

  const buildRepositoryOptionSet = () => {
    const emptyRepository= {name: "Choose a Repository to analyze", remoteRepo: "NOTHING"}
    const allChoices = [emptyRepository].concat(props.repositories)
    return allChoices.map((repository) =>
      buildRepositoryOption(repository)
    );
  };

  const repositoriesSelectBox = () => {
    return (
      <select
        onChange={(event) => props.onSelectChange(event.currentTarget.value)}
      >
        {buildRepositoryOptionSet()}
      </select>
    );
  };

  const newRepositoryInputBox = () => {
    return (
      <input
        type="text"
        onChange={(event) => props.onInputChange(event.currentTarget.value)}
      />
    );
  };

  return (
    <label>
      <div style={HeaderStyle}>Repository Picker</div>
      Repository to analyze:
      {repositoriesSelectBox()}
      <br />
      Add new repository url:
      {newRepositoryInputBox()}
    </label>
  );
};
