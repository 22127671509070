import { Html } from "@react-three/drei";
import React, { CSSProperties } from "react";

import { parseNodeName } from "../../utils/utils";

export interface ILabelProps {
  name: string;
  level: number;
  maxLevel: number;
  transparency: number;
  hoveringFunction: (e?: string) => void;
}

// transparency is bounded between the 0.4 and 1.0 values
// 0.4 being the root, 1 being the leaves
const colorByLevel = (branchIndex: number, numberOfSteps: number) => {
  const stepValue = (branchIndex - 1) / numberOfSteps;
  return `rgba(0, 0, 0, ${1 - stepValue})`;
};

const sizeByLevel = (branchIndex: number) => {
  return `${6 + 6 * branchIndex}pt`;
};

export const LabelComponent = (props: ILabelProps) => {
  const style: CSSProperties = {
    color: colorByLevel(props.level, props.maxLevel),
    fontSize: sizeByLevel(props.level),
    opacity: props.transparency,
    zIndex: 400,
  };
  return (
    <Html center={true} prepend={true} zIndexRange={[40, 0]}>
      <div
        style={style}
        onPointerOver={(e) => {
          e.stopPropagation();
          props.hoveringFunction(props.name);
        }}
        onPointerOut={(e) => {
          e.stopPropagation();
          props.hoveringFunction(undefined);
        }}
      >
        {parseNodeName(props.name)}
      </div>
    </Html>
  );
};
