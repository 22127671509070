import { Metrics } from "../SettingsOptions";

export interface MetricSelectorProps {
  metrics: Metrics;
  onMetricChange: (shortRepoName: string) => void;
}

export const MetricSelector = (props: MetricSelectorProps) => {
  const buildOption = (metric: string) => {
    return (
      <option key={metric} value={metric}>
        {metric}
      </option>
    );
  };

  const buildOptionSet = () => {
    return props.metrics.map((metric) => buildOption(metric));
  };

  const metricsSelectBox = () => {
    return (
      <select
        style={{ marginLeft: "10px" }}
        onChange={(event) => props.onMetricChange(event.currentTarget.value)}
      >
        {buildOptionSet()}
      </select>
    );
  };

  return (
    <label>
      Metric:
      {metricsSelectBox()}
    </label>
  );
};
