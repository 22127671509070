import { Line } from 'rc-progress';
import React, { CSSProperties } from 'react';

export interface TrackerOptions {
  name: string;
  index: number;
  total: number;
}

export const ProgressTracker = (props: TrackerOptions) => {
  const { name, index, total } = props;
  return (
    <div style={asSplashScreenStyle}>
      {name} ({index}/{total})
      <Line
        percent={(100 * index) / total}
        strokeWidth={1}
        strokeColor="#343434"
      />
    </div>
  );
};

const asSplashScreenStyle: CSSProperties = {
  position: "fixed",
  backgroundColor: "lightgrey",
  color: "black",
  width: "450px",
  height: "220px",
  // position: "absolute",
  top: "0",
  bottom: "0",
  left: "0",
  right: "0",
  margin: "auto",
  border: "4px solid black",
  fontSize: "20px",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
};
