export type BackendStatus =
  | "ACK"
  | "READY"
  | "RUNNING"
  | "DOWNLOADING"
  | "GITSHOW"
  | "BUILDMETRICTREE"
  | "ALGORITHMSTARTED"
  | "COMPLETED";

export type AvailableStatus =
  | "ACK"
  | "READY"
  | "RUNNING"
  | "DOWNLOADING"
  | "GITSHOW"
  | "BUILDMETRICTREE"
  | "ALGORITHMSTARTED"
  | "COMPLETED";

export type StatusMapping = {
  [P in BackendStatus]: AvailableStatus;
};

export const statusMapping: StatusMapping = {
  ACK: "ACK",
  COMPLETED: "COMPLETED",
  DOWNLOADING: "DOWNLOADING",
  GITSHOW: "GITSHOW",
  BUILDMETRICTREE: "BUILDMETRICTREE",
  ALGORITHMSTARTED: "ALGORITHMSTARTED",
  RUNNING: "RUNNING",
  READY: "READY",
};
