import { NameFiltering } from "./components/settings/nameFilteringDefiner/NameFiltering";
import { SeriesFilteringOptions } from "./components/settings/seriesFiltering/SeriesFilteringOptions";
import { SettingsOptions } from "./components/settings/SettingsOptions";
import { BoundingBox } from "./components/types/types";

export type TimeSamplingOptions ="BY_DAY" | "BY_WEEK" | "BY_MONTH" | undefined

export interface BackendMessage {
  git_path: string;
  boundingBox: BoundingBox;
  shrinking: boolean;
  treeDataLimits: {
    last: Number | undefined;
    sampling: Number | undefined;
    byTime: TimeSamplingOptions;
  };
  metric: string;
  nameFiltering: NameFiltering;
}

export const buildFromRepoOptions = (
  options: SettingsOptions
): BackendMessage => {
  const x = {
    git_path: options.repository,
    boundingBox: {
      horizontal: options.boundingBox.horizontal,
      vertical: options.boundingBox.vertical,
    },
    shrinking: options.shrinking,
    treeDataLimits: setBackendRangeOptions(options.filteringOptions),
    metric: options.metric,
    nameFiltering: options.nameFiltering,
  };
  console.log(x)
  return x
};

const setBackendRangeOptions = (options: SeriesFilteringOptions) => {
  return {
    sampling: options.samplingOptions.enabled
      ? options.samplingOptions.frequency
      : undefined,
    last: options.lastElementOptions.enabled
      ? options.lastElementOptions.amount
      : undefined,
    byTime: options.byDateGrouping,
  };
};
